// ----------------------------------------
//  ホスト情報
// ----------------------------------------
// export const baseUrl = "";                                           // ローカル
export const baseUrl = "https://remotemaint.citizen-systems.co.jp";  // OCI
//export const baseUrl = "https://csjdev.citizen-systems.co.jp";                      // Test server
// export const baseUrl = "https://rmstg.citizen-systems.co.jp";
      // OCI staging server
      
export const cgiUrl = baseUrl + "/cgi-bin/csjapp.cgi";

// ----------------------------------------
//  バージョン情報
// ----------------------------------------
export const version = "1.2.0";
export const admin_version = "1.2.0";
